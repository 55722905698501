import Container from "react-bootstrap/Container";
import Query from "../../components/Query";

import { Splide, SplideSlide } from "@splidejs/react-splide";

import REVIEW_QUERY from "../../queries/review";

function Review() {
  return (
    <Query query={REVIEW_QUERY}>
      {({ data: { review } }) => {
        const element = review.data.attributes;
        return (
          <section className="layout layout__color_primary pb-0">
            <Container fluid>
              <h2 data-aos="fade-up">{element.title}</h2>
              <Splide
                options={{
                  rewind: true,
                  autoWidth: true,
                  gap: "1rem",
                  pagination: false,
                  arrows: false,
                }}
                aria-label="My Favorite Images"
                className="w-100 layout_mt"
              >
                {element.review.map((item, index) => {
                  return (
                    <SplideSlide data-aos="fade-up" key={`review_${index}`}>
                      <h3 className="layout_mb__30 tt-normal">{item.name}</h3>
                      <p
                        className="layout_mb__30 layout_text"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                      <span
                        className={`layout_badge ${
                          item.type === "google"
                            ? "layout_badge__light"
                            : "layout_badge__primary"
                        }`}
                      >
                        <span
                          className={`layout_badge_icon layout_badge_${
                            item.type === "google"
                              ? "google"
                              : "clickclickdrive"
                          }`}
                        ></span>{" "}
                        {item.scores}
                      </span>
                    </SplideSlide>
                  );
                })}
              </Splide>
              <h4 data-aos="fade-up" className="h3 layout_mt layout_mb">
                Mehr Bewertungen hier:
              </h4>
              <div className="fix_left">
                <a
                  data-aos="fade-up"
                  href={element.glink}
                  className="btn btn__fill btn__fill_solid me-2 me-sm-4 me-md-5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GOOGLE
                </a>
                <a
                  data-aos="fade-up"
                  href={element.clickclicklink}
                  className="btn btn__fill btn__fill_solid"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ClickClickDrive
                </a>
              </div>
            </Container>
          </section>
        );
      }}
    </Query>
  );
}

export default Review;
