import Container from 'react-bootstrap/Container';

// import {Helmet} from "react-helmet-async";
import Query from '../../components/Query';
import IMPRESSUM_QUERY from '../../queries/impressum';

import ReactMarkdown from "react-markdown";

const Impressum = () => {
    return(
        <Query query={IMPRESSUM_QUERY}>
            {({ data: { imprint } }) => {
                const elem = imprint.data.attributes;
                return(
                    <>
                        {/* <Helmet>
                            <title>Impressum - Fahrschule-HafenCity</title>
                            <meta name="description" content="Fahrschule HafenCity Baakenallee 90 20457 Hamburg. UST ID: DE335135845. Vertreten durch: Inhaber: Yasin Karaoglan. Kontakt Mobile: 0176 620 504 70" />
                            <meta name="keywords" content="fahrschule hafencity, hafencity fahrschule, auto fahren lernen, fahren lernen, führerschein theorie, intensivfahrschule, intensiv fahrschule, fahrschule intensivkurs, theorieprüfung, b197 führerschein, führerschein ab 17, automatik führerschein, BE führerschein"/>
                            <link rel="canonical" href={window.location.href} />
                            <meta property="og:title" content="Impressum - Fahrschule-HafenCity" />
                            <meta property="og:description" content="Fahrschule HafenCity Baakenallee 90 20457 Hamburg. UST ID: DE335135845. Vertreten durch: Inhaber: Yasin Karaoglan. Kontakt Mobile: 0176 620 504 70" />
                            <meta property="og:type" content="website" />
                            <meta property="og:url" content={window.location.href} />
                        </Helmet> */}
                        <main className="layout layout__color_primary">
                            <Container fluid>
                                <h1 data-aos="fade-up" className='layout_mb'>{elem.title}</h1>
                                <div data-aos="fade-up" className='layout_text layout_text__special'>
                                    <ReactMarkdown children={elem.content} />
                                </div>
                                {/* <h2 data-aos="fade-up" className='layout_mb__30'>Angaben gemäß § 5 TMG</h2>
                                <p data-aos="fade-up" className='layout_text'>Fahrschule HafenCity</p>
                                <a data-aos="fade-up" href="https://goo.gl/maps/e8EUGjRfYqEt987V8" target="_blank" rel="noopener noreferrer" className="layout_text layout_text__link2 d-inline-block layout_mb__30">
                                    Alte Holstenstraße 23<br/>
                                    21031 Hamburg    
                                </a>
                                <p data-aos="fade-up" className='layout_text layout_mb__30'>UST ID: DE335135845</p>
                                <h3 data-aos="fade-up">Vertreten durch:</h3>
                                <p data-aos="fade-up" className='layout_text layout_mb__30'>Inhaber: Yasin Karaoglan</p>
                                <h3 data-aos="fade-up">Kontakt:</h3>
                                <p data-aos="fade-up" className='layout_text'>Telefon: <a href="tel:040 531 046 05" className="layout_text layout_text__link2">040 / 531 046 05</a></p>
                                <p data-aos="fade-up" className='layout_text'>Mobil: <a href="tel:0176 620 504 70" className="layout_text layout_text__link2">0176 / 620 504 70</a></p>
                                <p data-aos="fade-up" className='layout_text layout_mb__30'>E-Mail: <a href="mailto:info@fahrschulehafencity.de" className="layout_text layout_text__link2">info@fahrschulehafencity.de</a></p>
                                <p data-aos="fade-up" className='layout_text'>Allgemeine Geschäftsbedingungen sind in Bearbeitung und folgen zeitnah.</p> */}
                            </Container>
                        </main> 
                    </>
                )
            }}
        </Query>

    )
}

export default Impressum;