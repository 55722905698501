import React, { Component } from "react";
import FormUserDetails from "./FormUserDetails";
import FormPersonalDetails from "./FormPersonalDetails";
import Success from "./Success";
import { FormNewDetails } from "./FormNevDetails";

export class UserForm extends Component {
  state = {
    step: 1,
    Vorname: "",
    Nachname: "",
    Straße_und_Hausnummer: "",
    PLZ: "",
    Ort: "",
    Email: "",
    Telefonnummer: "",
    Geburtsdatum: "",
    Geburtsort: "",
    Geburtsland: "",
    Staatsangehörigkeit: "",
  };

  constructor(props) {
    super(props);
    this.newform = props.newform;
    console.log("newform", this.newform);
  }

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    const { step } = this.state;
    const {
      Vorname,
      Nachname,
      Straße_und_Hausnummer,
      PLZ,
      Ort,
      Email,
      Telefonnummer,
      Geburtsdatum,
      Geburtsort,
      Geburtsland,
      Staatsangehörigkeit,
    } = this.state;
    const values = {
      Vorname,
      Nachname,
      Straße_und_Hausnummer,
      PLZ,
      Ort,
      Email,
      Telefonnummer,
      Geburtsdatum,
      Geburtsort,
      Geburtsland,
      Staatsangehörigkeit,
    };

    switch (step) {
      case 1:
        return <FormUserDetails nextStep={this.nextStep} />;
      case 2:
        return (
          <>
            {this.newform ? (
              <FormNewDetails
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                values={values}
              ></FormNewDetails>
            ) : (
              <FormPersonalDetails
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                values={values}
              />
            )}
          </>
        );
      case 3:
        return <Success hideModal={this.props.hide} />;
      default:
        console.log("This is a multi-step form built with React.");
    }
  }
}

export default UserForm;
