import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import Modal from "react-bootstrap/Modal";

function Cookie() {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	function setCookie(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie =
			name + "=" + (value || "") + expires + "; path=/ ; sameSite=Lax";
	}

	const acceptCookie = () => {
		setCookie("cookie_privacy_policy", "true", 366);
		document.querySelector(".CookieConsent").remove();
		handleClose();
	};

	const notGoogle = () => {
		setCookie("cookie_privacy_policy", "part", 366);
		setCookie("_ga", "", -1);
		document.querySelector(".CookieConsent").remove();
		handleClose();
	};

	function getCookie(name) {
		var matches = document.cookie.match(
			new RegExp(
				"(?:^|; )" +
					name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
					"=([^;]*)"
			)
		);
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	window.addEventListener("load", () => {
		setTimeout(() => {
			const google = getCookie("cookie_privacy_policy");
			if (google === "part") {
				setCookie("_ga", "", -1);
			}
		}, 10);
	});

	return (
		<>
			<CookieConsent
				disableStyles="false"
				buttonText="AKZEPTIEREN"
				buttonClasses="btn btn__fill btn__fill_solid me-auto me-xl-0 ms-auto"
				buttonWrapperClasses="d-flex"
				cookieName="cookie_privacy_policy"
				cookieValue="true"
			>
				<h3 className="fw-normal mb-3 tt-normal">Cookies</h3>
				<p className="layout_mb__30">
					Diese Website verwendet Cookies und ähnliche Technologien, um Ihre
					Erfahrung zu verbessern und Ihnen personalisierte Inhalte und Werbung
					bereitzustellen. Einige dieser Cookies sind für das reibungslose
					Funktionieren der Webseite unerlässlich, während andere uns dabei
					unterstützen, die Nutzung der Website zu analysieren und zu
					optimieren. Indem Sie auf "Akzeptieren" klicken, erklären Sie sich mit
					der Verwendung von Cookies einverstanden. Weitere Informationen
					darüber, wie wir Cookies verwenden und wie Sie Ihre Einstellungen
					anpassen können, finden Sie in unserer Datenschutzerklärung.{" "}
				</p>
				<div className="d-flex mb-4 mb-xl-0">
					<button
						className="btn btn__fill btn__fill_solid btn__fill_solid_primary ms-auto ms-xl-0 me-auto"
						onClick={handleShow}
					>
						PERSONALISIEREN
					</button>
				</div>
			</CookieConsent>

			<Modal
				show={show}
				onHide={handleClose}
				backdrop="true"
				keyboard={false}
				contentClassName="modal_cookieConsent"
				centered
			>
				<Modal.Body>
					<h3 className="fw-normal layout_mb tt-normal">Cookies</h3>
					<p className="layout_mb">
						Essenziell -{" "}
						<a
							href="https://automattic.com/de/cookies/"
							target="__blank"
							rel="noopener noreferrer"
						>
							Allgemeine Cookies
						</a>
					</p>
					<p className="layout_mb">
						Besucher Zähldienste -{" "}
						<a
							href="https://support.google.com/analytics/answer/6004245"
							target="__blank"
							rel="noopener noreferrer"
						>
							Google Analytics
						</a>
					</p>
					<div className="d-flex mb-4 mb-xl-0">
						<button
							className="btn btn__fill btn__fill_solid btn__fill_solid_primary ms-auto ms-xl-0 me-auto"
							onClick={notGoogle}
						>
							ALLE ERLAUBEN
						</button>
						<button
							className="btn btn__fill btn__fill_solid me-auto me-xl-0 ms-auto"
							onClick={acceptCookie}
						>
							ALLE ABLEHNEN
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default Cookie;
