import React from "react";
import Query from "../../components/Query";
import { Link } from "react-router-dom";

import AUSBILDUNG_QUERY from "../../queries/ausbildung";
import CATEGORIES_QUERY from "../../queries/category/categories";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FAQ from "../../components/Faq"
import Review from "../../components/Review"

import Anmelden from '../../components/Content/anmelden';

// import {Helmet} from "react-helmet-async";

const Ausbildung = () => {
  return (
    <Query query={AUSBILDUNG_QUERY}>
      {({ data: { education } }) => {
        const ausbild = education.data.attributes;
        
        return(
          <>
            {/* <Helmet>
                  <title>{ausbild.seo.metaTitle}</title>
                  <meta name="description" content={ausbild.seo.metaDescription} />
                  <meta name="keywords" content={ausbild.seo.keywords} />
                  <link rel="canonical" href={window.location.href} />
                  <meta property="og:title" content={ausbild.seo.metaTitle} />
                  <meta property="og:description" content={ausbild.seo.metaDescription} />
                  <meta property="og:type" content="website" />
                  <meta property="og:url" content={window.location.href} />
                  <meta property="og:image" content={process.env.REACT_APP_BACKEND_URL + ausbild.seo.metaImage.data.attributes.url} />
              </Helmet> */}
            <header className="layout layout__min layout__bg layout__bg_primary">
              <Container fluid>
                <Row className='justify-content-center justify-content-lg-start'>
                  <Col xs={12} md={7} xl={5} xxl={6} className="">
                    <h1 data-aos="fade-up" data-aos-offset="-100" className="layout_title layout_title__light">{ausbild.title}</h1>
                  </Col>
                  <Col xs={12} md={5} xl={4} className='offset-xl-2 offset-xxl-1'>
                    <h4 data-aos="fade-up" data-aos-offset="-100" className="h3 tt-normal">{ausbild.description}</h4>                  
                  </Col>
                  <span className="layout_mt_min"></span>
                  <Col xs={12} md={12} lg={7} xl={5}>
                    <img data-aos="fade-up" data-aos-offset="-100" src={process.env.REACT_APP_BACKEND_URL + ausbild.thumbnail.data.attributes.url} alt={ausbild.title} className="autoImg layout_image layout_image__round"/>
                  </Col>
                  <Col xs={12} md={10} lg={5} xl={4} className='offset-xl-2'>
                  <Query query={CATEGORIES_QUERY} id={null}>
                    {({ data: { categories } }) => {
                      return (
                        <Row className="justify-content-between">
                          {categories.data.slice(0,6).map((category, index) => {
                            return(
                            <Col data-aos="fade-up" data-aos-offset="-100" xs={index%2 === 1 ? 8 : 4} className={`layout_mb_header ${ index%2 === 1 ? "text-end" : " "}`} key={category.attributes.slug}>
                              <Link
                                to={`/fuehrerscheine/${category.attributes.slug}`}
                                className="btn btn__border btn__border_white"
                                target="_top" 
                                rel="noopener noreferrer"
                              >
                              {category.attributes.title}
                              </Link>
                            </Col>
                            ) 
                          })}
                        </Row>
                      );
                    }}   
                  </Query>              
                  </Col>
                </Row>
              </Container>
            </header>
            {/*  */}
            <section className='layout layout__color_primary'>
              <Container fluid>
                <Row>
                  <Col md={12} lg={12} xl={6} xxl={7} className="mb-5 mb-xl-0 layout_mb__100">
                    <h2 data-aos="fade-up" 
                    dangerouslySetInnerHTML={{__html: ausbild.course.title + `
                    <svg class="contentSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 78 78">
                      <defs>
                        <clipPath id="clip-thicker">
                          <rect width="78" height="78"/>
                        </clipPath>
                      </defs>
                      <g id="thicker" clip-path="url(#clip-thicker)">
                        <path id="_" data-name="*" d="M37.5-39.864h-10l.479-20.344L10.588-49.571,5.648-58.25l17.93-9.705L5.648-77.66l4.94-8.678L27.981-75.7,27.5-96.045h10L37.019-75.7,54.412-86.338l4.94,8.678-17.93,9.705,17.93,9.705-4.94,8.678L37.019-60.208Zm-6.925-3h3.853L33.89-65.637,53.336-53.746l1.892-3.325L35.119-67.955,55.229-78.839l-1.892-3.325L33.89-70.272l.536-22.774H30.573l.536,22.774L11.664-82.164,9.771-78.839,29.881-67.955,9.771-57.07l1.892,3.325L31.11-65.637Z" transform="translate(6.352 107.045)" fill="#00539c"/>
                      </g>
                    </svg>
                    `}}></h2>
                  </Col>
                  <Col xs={12} md={10} lg={9} xl={6} xxl={5} className="">
                    <Row className="flex-column">
                      {ausbild.course.courses.map((item, index) => {
                        return(
                          <Col data-aos="fade-up" className="layout_mb__100" key={`course_${index}`}>
                            <h4 className='layout_title layout_title__subitem layout_mb__30 tt-normal'>{item.title}</h4>
                            <p className="layout_text ps-lg-5">{item.description}</p>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-xxl-end">
                      <Col xs={12} md={8} xl={6} xxl={4}>
                        <p data-aos="fade-up" className="layout_text" dangerouslySetInnerHTML={{__html: `
                          <svg class="contentSvg_min" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 78 78">
                            <defs>
                              <clipPath id="clip-thicker">
                                <rect width="78" height="78"/>
                              </clipPath>
                            </defs>
                            <g id="thicker" clip-path="url(#clip-thicker)">
                              <path id="_" data-name="*" d="M37.5-39.864h-10l.479-20.344L10.588-49.571,5.648-58.25l17.93-9.705L5.648-77.66l4.94-8.678L27.981-75.7,27.5-96.045h10L37.019-75.7,54.412-86.338l4.94,8.678-17.93,9.705,17.93,9.705-4.94,8.678L37.019-60.208Zm-6.925-3h3.853L33.89-65.637,53.336-53.746l1.892-3.325L35.119-67.955,55.229-78.839l-1.892-3.325L33.89-70.272l.536-22.774H30.573l.536,22.774L11.664-82.164,9.771-78.839,29.881-67.955,9.771-57.07l1.892,3.325L31.11-65.637Z" transform="translate(6.352 107.045)" fill="#00664e"/>
                            </g>
                          </svg>
                        ` + ausbild.course.description}}></p>
                      </Col>
                      <Col data-aos="fade-up" xs={12} md={4} xl={6} xxl={5} className="offset-xxl-3 text-end text-md-start mt-5 mt-md-0">
                        <Anmelden btn="btn btn__big btn__border btn__border_primary btn__border_primary_small"></Anmelden>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
            {/*  */}
            <section className='layout layout__bg layout__bg_primary'>
              <Container fluid>
                <Row>
                  <Col md={12} lg={12} xl={6} xxl={7} className="mb-5 mb-xl-0 position-relative">
                    <h2 data-aos="fade-up" className="layout_mb__100">{ausbild.advantages.title}</h2>
                    <img data-aos="fade-up" src={process.env.REACT_APP_BACKEND_URL + ausbild.advantages.thumb.data.attributes.url} alt={ausbild.advantages.title} className="layout_image layout_image__round layout_image__sticky"/>
                  </Col>
                  <Col xs={12} md={10} lg={9} xl={6} xxl={5} className="">
                    <Row className="flex-column">
                      {ausbild.advantages.advantageslist.map((item, index) => {
                        return(
                          <Col data-aos="fade-up" className={index === ausbild.advantages.advantageslist.length - 1 ? 'mb-0' : "layout_mb__100"} key={`advantage_${index}`}>
                            <h4 className='layout_title layout_title__light layout_title__subitem layout_mb__30'>{item.title}</h4>
                            <p className="layout_text ps-lg-5">{item.description}</p>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
                
              </Container>
            </section>
            {/*  */}
            <Review></Review>
            {/*  */}
            <section className='layout layout__color_primary'>
              <Container fluid>
                <h2 data-aos="fade-up">FAQ</h2>
                <h2 data-aos="fade-up">{ausbild.faq.title}</h2>
                <FAQ elements={ausbild.faq.qa}></FAQ>
              </Container>
            </section>
          </>
        )
      }}
    </Query>
  );
};

export default Ausbildung;