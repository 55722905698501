import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import logo_1 from "../../img/logo_1.png";
import logo_2 from "../../img/logo_2.png";

function StandorteBlock({ title, description }) {
  return (
    <>
      <section className="layout layout__color_primary">
        <Container fluid>
          <Row>
            <Col className="layout_mb">
              <h2
                data-aos="fade-up"
                dangerouslySetInnerHTML={{ __html: title }}
              ></h2>
            </Col>
            <Col xs={12} sm={11} lg={8} xl={6} xxl={6}>
              <p
                data-aos="fade-up"
                className="layout_text"
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="layout layout__color_primary pt-0">
        <Container fluid>
          <Row className="justify-content-xl-between justify-content-center gap-4">
            <Col xs={12} sm={11} lg={8} xl={5} xxl={5}>
              <a
                href="https://fahrschule-bergedorf.de/"
                target="_blank"
                className="photoCard photoCard_bf"
                data-aos="fade-up"
              >
                <img src={logo_1} alt="fahrschule-bergedorf" />
                <div className="photoCard_link">
                  Webseite besuchen
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="22"
                    viewBox="0 0 11 22"
                    fill="none"
                  >
                    <path
                      d="M2 2L9.5 11L2 20"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </a>
            </Col>
            <Col xs={12} sm={11} lg={8} xl={5} xxl={5}>
              <a
                href="https://www.geesthacht-fahrschule.de/"
                target="_blank"
                className="photoCard photoCard_gt"
                data-aos="fade-up"
              >
                <img src={logo_2} alt="geesthacht-fahrschule" />
                <div className="photoCard_link">
                  Webseite besuchen
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="22"
                    viewBox="0 0 11 22"
                    fill="none"
                  >
                    <path
                      d="M2 2L9.5 11L2 20"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default StandorteBlock;
