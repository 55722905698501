import { useEffect, useRef, useState } from "react";

const WhatsUp = () => {
  const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const container = useRef(null);

  const handleShow = () => {
    if (!isMobile) {
      window.open("https://wa.me/17662050470", "_blank");
    } else {
      setShow(!show);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
      setShow(false);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
        setShow(false);
      }
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (window.innerWidth < 992) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
          setShow(false);
        }
      });
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobile) {
        if (container.current && !container.current.contains(event.target)) {
          setShow(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobile, container]);

  return (
    <div className="whatsUpBTN" ref={container}>
      {show && (
        <div className="whatsUpBTN__popup" data-aos="fade-up">
          <a
            className={`btn btn__fill btn__fill_primary`}
            variant="primary"
            href="tel:0176 620 504 70"
            target="_blank"
            rel="noreferrer"
          >
            ANRUFEN
          </a>
          <a
            className={`btn btn__fill btn__fill_primary`}
            variant="primary"
            href="https://wa.me/17662050470"
            target="_blank"
            data-aos="fade-up"
            rel="noreferrer"
          >
            WHATSAPP
          </a>
        </div>
      )}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="74"
        height="74"
        viewBox="0 0 74 74"
        fill="none"
        onClick={handleShow}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.2183 10.754C56.262 3.82285 47.0106 0.00406494 37.1548 0C16.8458 0 0.317228 16.449 0.309059 36.6662C0.306336 43.129 2.00276 49.4378 5.22723 54.9987L0 74L19.5325 68.9008C24.9145 71.8225 30.9736 73.3623 37.1398 73.3641H37.1552C57.4619 73.3641 73.9923 56.9137 74 36.6956C74.0041 26.8973 70.1751 17.6848 63.2183 10.754Z"
          className="whatsUpBTN__bg"
        />
        <path
          className="whatsUpBTN__icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.0574 43.7777C53.149 43.3226 48.6815 41.1251 47.8483 40.8214C47.0161 40.5182 46.4096 40.3672 45.8045 41.2765C45.1984 42.1858 43.4574 44.2328 42.927 44.8388C42.3967 45.4453 41.8673 45.5215 40.9584 45.0664C40.0496 44.6117 37.122 43.6518 33.651 40.5563C30.9501 38.1469 29.1266 35.1713 28.5963 34.2621C28.0668 33.3519 28.5918 32.9076 28.9949 32.4077C29.9785 31.1862 30.9635 29.9056 31.2663 29.2996C31.5696 28.6931 31.4177 28.1623 31.1902 27.7076C30.9635 27.253 29.1463 22.7805 28.3893 20.9606C27.6511 19.1895 26.9027 19.4287 26.3446 19.4009C25.8151 19.3745 25.2091 19.3691 24.603 19.3691C23.9975 19.3691 23.0129 19.5962 22.1798 20.5064C21.3471 21.4161 19 23.6141 19 28.0866C19 32.5591 22.2559 36.8797 22.7101 37.4862C23.1643 38.0927 29.1176 47.2706 38.2324 51.206C40.4003 52.1431 42.0926 52.7016 43.4126 53.1204C45.5895 53.812 47.5697 53.7144 49.1357 53.4806C50.8817 53.2194 54.5112 51.2822 55.2691 49.1599C56.026 47.0372 56.026 45.2182 55.7985 44.8388C55.5718 44.4599 54.9658 44.2328 54.0574 43.7777Z"
        />
      </svg>
    </div>
  );
};

export default WhatsUp;
