import React, { Component, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useForm } from "react-hook-form";
import {
  InlineWidget,
  PopupModal,
  useCalendlyEventListener,
} from "react-calendly";

function MyForm(props) {
  const { handleChange, values, curentPage } = props;
  const [isOpen, setIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      const { payload } = e.data;

      axios({
        method: "get",
        url: payload.event.uri,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_TOKEN}`,
          ContentType: "application/json",
        },
      }).then((res) => {
        const { data } = res;
        if (data.resource.start_time) {
          const startTime = new Date(data.resource.start_time).toLocaleString(
            "de-DE",
            { timeZone: "Europe/Berlin" }
          );

          console.log("data", startTime);
          axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_URL}/api/email/`,
            data: {
              to: "kontakt@fahrschule-bergedorf.de",
              from: "kontakt@fahrschule-bergedorf.de",
              subject: `${
                curentPage === "erste-hilfe"
                  ? "HA: Erste Hilfe Kurs - Neue Anmeldung Fahrschule Hafencity"
                  : "HA: Anfrage von der Webseite Fahrschule Hafencity"
              }`,
              text: `
                          <p>Vorname: ${values.Vorname}</p>
                          <p>Nachname: ${values.Nachname}</p>
                          <p>Straße und Hausnummer: ${values.Straße_und_Hausnummer}<br/>
                          <p>PLZ: ${values.PLZ}</p>
                          <p>Ort: ${values.Ort}</p>
                          <p>Email: ${values.Email}</p>
                          <p>Telefonnummer: ${values.Telefonnummer}</p>
                          <p>Geburtsdatum: ${values.Geburtsdatum}</p>
                          <br>
                          <p><strong>Calendly Termin</strong>: ${startTime}</p>
                          `,
            },
          });
        }
        props.continue();
      });
    },
  });

  const onSubmit = (data) => {
    if (data.Termin) {
      setIsOpen(true);
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/email/`,
        data: {
          to: "kontakt@fahrschule-bergedorf.de",
          from: "kontakt@fahrschule-bergedorf.de",
          subject: `${
            curentPage === "erste-hilfe"
              ? "HA: Erste Hilfe Kurs - Neue Anmeldung Fahrschule Hafencity"
              : "HA: Anfrage von der Webseite Fahrschule Hafencity"
          }`,
          text: `
                      <p>Vorname: ${values.Vorname}</p>
                      <p>Nachname: ${values.Nachname}</p>
                      <p>Straße und Hausnummer: ${values.Straße_und_Hausnummer}<br/>
                      <p>PLZ: ${values.PLZ}</p>
                      <p>Ort: ${values.Ort}</p>
                      <p>Email: ${values.Email}</p>
                      <p>Telefonnummer: ${values.Telefonnummer}</p>
                      <p>Geburtsdatum: ${values.Geburtsdatum}</p>
                      `,
        },
      });

      props.continue();
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="layout_mb__30">
        <Col md={6} className="layout_mb__30">
          <FloatingLabel label="Vorname">
            <Form.Control
              id="Vorname"
              type="text"
              placeholder="Vorname"
              {...register("Vorname", { required: true, maxLength: 15 })}
              onChange={handleChange("Vorname")}
            />
          </FloatingLabel>
          {errors.Vorname && <p>Bitte das Feld ausfüllen</p>}
        </Col>
        <Col md={6} className="layout_mb__30">
          <FloatingLabel label="Nachname">
            <Form.Control
              id="Nachname"
              type="text"
              placeholder="Nachname"
              {...register("Nachname", { required: true })}
              onChange={handleChange("Nachname")}
            />
          </FloatingLabel>
          {errors.Nachname && <p>Bitte das Feld ausfüllen</p>}
        </Col>
        <Col xs={12} className="layout_mb__30">
          <FloatingLabel label="Straße und Hausnummer">
            <Form.Control
              id="Straße_und_Hausnummer"
              type="text"
              placeholder="Straße und Hausnummer"
              {...register("Straße_und_Hausnummer")}
              onChange={handleChange("Straße_und_Hausnummer")}
            />
          </FloatingLabel>
        </Col>
        <Col md={6} className="layout_mb__30">
          <FloatingLabel label="PLZ">
            <Form.Control
              id="PLZ"
              type="text"
              placeholder="PLZ"
              {...register("PLZ")}
              onChange={handleChange("PLZ")}
            />
          </FloatingLabel>
        </Col>
        <Col md={6} className="layout_mb__30">
          <FloatingLabel label="Ort">
            <Form.Control
              id="Ort"
              type="text"
              placeholder="Ort"
              {...register("Ort")}
              onChange={handleChange("Ort")}
            />
          </FloatingLabel>
        </Col>
        <Col xs={12} className="layout_mb__30">
          <FloatingLabel label="E-Mail Adresse">
            <Form.Control
              id="Email"
              type="email"
              placeholder="E-Mail Adresse"
              {...register("Email", {
                required: true,
                pattern: `/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/`,
              })}
              onChange={handleChange("Email")}
            />
          </FloatingLabel>
          {errors.Email && <p>Bitte das Feld ausfüllen</p>}
        </Col>
        <Col xs={12} className="layout_mb__30">
          <FloatingLabel label="Telefonnummer">
            <Form.Control
              id="Telefonnummer"
              type="phone"
              placeholder="Telefonnummer"
              {...register("Telefonnummer", {
                required: true,
                pattern: `/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/`,
              })}
              onChange={handleChange("Telefonnummer")}
            />
          </FloatingLabel>
          {errors.Telefonnummer && <p>Bitte das Feld ausfüllen</p>}
        </Col>
        <Col xs={12} className="layout_mb__30">
          <FloatingLabel label="Geburtsdatum">
            <Form.Control
              id="Geburtsdatum"
              type="date"
              placeholder="Geburtsdatum"
              {...register("Geburtsdatum", { required: true })}
              onChange={handleChange("Geburtsdatum")}
            />
          </FloatingLabel>
          {errors.Geburtsdatum && <p>Bitte das Feld ausfüllen</p>}
        </Col>
        <Col xs={12} className="layout_mb__30 contactForm">
          <input
            id="Termin"
            type="checkbox"
            {...register("Termin", { required: false })}
            className="form-check-input"
            defaultChecked={true}
          />
          <label htmlFor="Termin" className="form-check-label green">
            Ich möchte einen Zeitslot für den Termin auswählen.
          </label>
        </Col>
        {/* <Col md={6} className="layout_mb__30">
          <FloatingLabel label="Geburtsort">
            <Form.Control
              id="Geburtsort"
              type="text"
              placeholder="Geburtsort"
              {...register("Geburtsort")}
              onChange={handleChange("Geburtsort")}
            />
          </FloatingLabel>
        </Col>
        <Col md={6} className="layout_mb__30">
          <FloatingLabel label="Geburtsland">
            <Form.Control
              id="Geburtsland"
              type="text"
              placeholder="Geburtsland"
              {...register("Geburtsland")}
              onChange={handleChange("Geburtsland")}
            />
          </FloatingLabel>
        </Col>
        <Col xs={12} className="layout_mb__30">
          <FloatingLabel label="Staatsangehörigkeit">
            <Form.Control
              id="Staatsangehörigkeit"
              type="text"
              placeholder="Staatsangehörigkeit"
              {...register("Staatsangehörigkeit")}
              onChange={handleChange("Staatsangehörigkeit")}
            />
          </FloatingLabel>
        </Col> */}
      </Row>
      <PopupModal
        url={process.env.REACT_APP_CALENDLY_URL}
        prefill={{
          firstName: values.Vorname,
          lastName: values.Nachname,
          email: values.Email,
          customAnswers: {
            a1: values.Telefonnummer,
            a2: values.Geburtsdatum,
            a3: values.Straße_und_Hausnummer,
            a4: values.PLZ,
            a5: values.Ort,
          },
        }}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
      <div className="d-flex">
        <Button color="secondary" variant="contained" onClick={props.back}>
          Zurück
        </Button>

        <Button
          type="sybmit"
          color="primary"
          variant="contained"
          className="btn__fill btn__fill_primary btn__fill_primary_static ms-auto"
        >
          NÄCHSTE -&gt;
        </Button>
      </div>
    </Form>
  );
}

export class FormNewDetails extends Component {
  curentPage = window.location.pathname
    .split("/")
    .find((item) => item === "erste-hilfe")
    ? "erste-hilfe"
    : "fuehrerschein";

  continue = (e) => {
    // <p>Geburtsort: ${this.props.values.Geburtsort}</p>
    // <p>Geburtsland: ${this.props.values.Geburtsland}</p>
    // <p>Staatsangehörigkeit: ${this.props.values.Staatsangehörigkeit}</p>
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
      <>
        <h3 className="h1 layout_mb" id="contained-modal-title-vcenter">
          VORANMELDUNG
        </h3>
        <MyForm
          curentPage={this.curentPage}
          values={this.props.values}
          handleChange={this.props.handleChange}
          back={this.back}
          continue={this.continue}
        ></MyForm>
      </>
    );
  }
}

export default FormNewDetails;
