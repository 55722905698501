import React from "react";
import Query from "../../components/Query";

import KONTAKT_QUERY from "../../queries/kontakt";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Anmelden from "../../components/Content/anmelden";
import ContactForm from "../../components/Form/FormContact";

// import {Helmet} from "react-helmet-async";

const Kontakt = () => {
  return (
    <Query query={KONTAKT_QUERY}>
      {({ data: { contact } }) => {
        const kontakt = contact.data.attributes;
        return (
          <>
            {/* <Helmet>
              <title>Kontakt - Fahrschule-HafenCity</title>
              <meta name="description" content="Mobil: 0176 620 504 70. Email: info@fahrschule-hafencity.de · Facebook. Instagram. TikTok. ANMELDEN." />
              <meta name="keywords" content="fahrschule hafencity, hafencity fahrschule, auto fahren lernen, fahren lernen, führerschein theorie, intensivfahrschule, intensiv fahrschule, fahrschule intensivkurs, theorieprüfung, b197 führerschein, führerschein ab 17, automatik führerschein, BE führerschein"/>
              <link rel="canonical" href={window.location.href} />
              <meta property="og:title" content="Kontakt - Fahrschule-HafenCity" />
              <meta property="og:description" content="Mobil: 0176 620 504 70. Email: info@fahrschule-hafencity.de · Facebook. Instagram. TikTok. ANMELDEN." />
              <meta property="og:type" content="website" />
              <meta property="og:url" content={window.location.href} />
            </Helmet> */}
            <main className="layout layout__min layout__bg layout__bg_primary layout__fullPage">
              <Container fluid>
                <h1
                  data-aos="fade-up"
                  className="layout_title layout_title__light layout_mb"
                >
                  Kontakt
                </h1>
                <Row>
                  <Col lg={6} xl={6}>
                    <p
                      data-aos="fade-up"
                      className="kontakt_map"
                      dangerouslySetInnerHTML={{
                        __html:
                          '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2371.1873757102244!2d10.0168628!3d53.536568499999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18fc379b18657%3A0x1041fc26f3fafec1!2sBaakenallee%2090%2C%2020457%20Hamburg%2C%20Deutschland!5e0!3m2!1sde!2sua!4v1673619560159!5m2!1sde!2sua" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
                      }}
                    ></p>
                  </Col>
                  <Col className="offset-xl-1 layout_mt__100 mt-lg-0">
                    <ContactForm />
                  </Col>
                  <Col xs={12} className="layout_mt__100">
                    <Row className="align-items-start flex-column flex-lg-row">
                      <Col className="order-lg-1 offset-xl-1">
                        {kontakt.telephone !== null ? (
                          <p
                            data-aos="fade-up"
                            className="footer_text text-center text-lg-start"
                          >
                            Tel:{" "}
                            <a href={"tel:" + kontakt.telephone}>
                              {kontakt.telephone}
                            </a>
                          </p>
                        ) : (
                          ""
                        )}
                        {kontakt.mobile !== null ? (
                          <p
                            data-aos="fade-up"
                            className="footer_text text-center text-lg-start"
                          >
                            Mobil:{" "}
                            <a href={"tel:" + kontakt.mobile}>
                              {kontakt.mobile}
                            </a>
                          </p>
                        ) : (
                          ""
                        )}
                        {kontakt.email !== null ? (
                          <p
                            data-aos="fade-up"
                            className="footer_text text-center text-lg-start"
                          >
                            Email:{" "}
                            <a href={"mailto:" + kontakt.email}>
                              {kontakt.email}
                            </a>
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col lg={6} xl={6} className="">
                        <Row className="flex-column">
                          <Col className="d-flex justify-content-center justify-content-lg-start layout_mt__100 mt-lg-0 order-1 order-lg-0">
                            <p
                              data-aos="fade-up"
                              className="footer_text pe-3 footer_text__small text-center text-xxl-start"
                            >
                              <a
                                href={kontakt.Facebook}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="icon"
                                  src="./facebook.svg"
                                  alt="Facebook"
                                />
                              </a>
                            </p>
                            <p
                              data-aos="fade-up"
                              className="footer_text pe-3 footer_text__small text-center text-xxl-start"
                            >
                              <a
                                href={kontakt.Instagram}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="icon icon__insta"
                                  src="./inst.svg"
                                  alt="Instagram"
                                />
                              </a>
                            </p>
                            <p
                              data-aos="fade-up"
                              className="footer_text footer_text__small text-center text-xxl-start"
                            >
                              <a
                                href={kontakt.TikTok}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="icon"
                                  src="./tiktik.svg"
                                  alt="TikTok"
                                />
                              </a>
                            </p>
                          </Col>
                          <Col
                            data-aos="fade-up"
                            className="text-center text-lg-start layout_mt__100"
                          >
                            <Anmelden
                              btn="btn btn__fill btn__fill_primary"
                              newform={false}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="layout_mt">
                  <Col xs={12} xxl={2}>
                    <p
                      data-aos="fade-up"
                      className="footer_text footer_text__small text-center text-xxl-start mb-3 mb-xxl-0"
                    >
                      <a href="/datenschutz">Datenschutz</a>
                    </p>
                  </Col>
                  <Col xs={12} xxl={2}>
                    <p
                      data-aos="fade-up"
                      className="footer_text footer_text__small text-center text-xxl-start mb-3 mb-xxl-0"
                    >
                      <a href="/impressum">Impressum</a>
                    </p>
                  </Col>
                  <Col
                    xs={12}
                    xxl={4}
                    className="layout_mt mt-xxl-0 offset-xxl-3"
                  >
                    <p
                      data-aos="fade-up"
                      className=" footer_text footer_text__small layout_text text-center text-xxl-start"
                    >
                      made by{" "}
                      <a
                        href="https://otruta.agency/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Otruta.agency.
                      </a>{" "}
                      2022
                    </p>
                  </Col>
                </Row>
              </Container>
            </main>
          </>
        );
      }}
    </Query>
  );
};

export default Kontakt;
